import { CapacitorStateStore } from '@capacitor-oidc-client-ts/capacitor-state-store';
import { isPlatform } from '@ionic/angular/standalone';

import { Environment } from './environment.model';

const redirectUri = isPlatform('capacitor')
  ? 'fua://test/signin-callback'
  : 'https://fua-test.flusso.nl/signin-callback';
const postLogoutRedirectUri = isPlatform('capacitor')
  ? 'fua://test/signout-callback'
  : `https://fua-test.flusso.nl/signout-callback`;

export const environment: Environment = {
  production: false,
  authentication: {
    settings: {
      authority: 'https://login-test.flusso.nl/auth/realms/flusso-utility-app/',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: 'fua-test',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      redirect_uri: redirectUri,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      post_logout_redirect_uri: postLogoutRedirectUri,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      response_type: 'code',
      userStore: new CapacitorStateStore(),
    },
  },
};
