import { Browser, OpenOptions } from '@capacitor/browser';
import { INavigator, IWindow, Logger, RedirectParams } from 'oidc-client-ts';

type BrowserOpenOptions = Omit<OpenOptions, 'url'> & {
  windowName?: 'top' | 'self';
};

export class CapacitorRedirectNavigator implements INavigator {
  private readonly _logger = new Logger(
    'capacitor-oidc-client-ts-redirect-navigator',
  );

  constructor(private browserOpenOptions?: BrowserOpenOptions) {}

  async prepare({
    redirectTarget = this.browserOpenOptions?.windowName ?? 'self',
  }: RedirectParams): Promise<IWindow> {
    this._logger.create('prepare');
    let abort: (reason: Error) => void;

    return {
      navigate: async ({ url }: { url: string }): Promise<never> => {
        this._logger.create('navigate');
        const promise = new Promise((resolve, reject) => {
          abort = reject;
        });
        await Browser.open({
          ...this.browserOpenOptions,
          url,
          windowName: `_${redirectTarget}`,
        });

        return await (promise as Promise<never>);
      },
      close: async (): Promise<void> => {
        this._logger.create('close');
        abort?.(new Error('Redirect aborted'));
        await Browser.close();
      },
    };
  }

  async callback(): Promise<void> {
    return;
  }
}
