import { computed, inject, Injectable, NgZone, signal } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { CapacitorRedirectNavigator } from '@capacitor-oidc-client-ts/navigators/capacitor-redirect-navigator';
import { Log, User, UserManager } from 'oidc-client-ts';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userManager: UserManager;
  private router: Router = inject(Router);
  private zone: NgZone = inject(NgZone);

  private user = signal<User | null>(null);
  getUser = computed<User | null>(() => this.user());
  isUserExpired = computed<boolean>(() => this.user()?.expired ?? true);

  constructor() {
    this.userManager = new UserManager(
      environment.authentication.settings,
      new CapacitorRedirectNavigator(),
    );

    this.userManager.events.addUserLoaded(async () =>
      this.user.set(await this.userManager.getUser()),
    );
    this.userManager.events.addUserUnloaded(async () =>
      this.user.set(await this.userManager.getUser()),
    );

    App.addListener('appUrlOpen', event => this.onAppUrlOpen(event));

    if (!environment.production) {
      Log.setLogger(console);
      Log.setLevel(Log.DEBUG);
    }
  }

  private onAppUrlOpen(event: URLOpenListenerEvent): void {
    this.zone.run(async () => this.init(event.url));
  }

  async init(path: string): Promise<void> {
    if (path.includes('signin-callback')) {
      // Sign in
      await this.userManager.signinCallback(path);
    } else if (path.includes('signout-callback')) {
      // Sign out
      await this.userManager.signoutCallback(path);
    }

    // Update user
    this.user.set(await this.userManager.getUser());

    return this.router
      .navigateByUrl('home', { replaceUrl: true })
      .then(() => undefined);
  }

  login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}
