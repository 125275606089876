import { Location } from '@angular/common';
import { APP_INITIALIZER, enableProdMode, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, RouteReuseStrategy, withComponentInputBinding } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { IonicRouteStrategy, getPlatforms, isPlatform, provideIonicAngular } from '@ionic/angular/standalone';

import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { AuthenticationService } from './app/authentication/authentication.service';
import { environment } from './environments/environment';
import { initializeApp } from './initialize-app';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes, withComponentInputBinding()),
    {
        provide: APP_INITIALIZER,
        useFactory: initializeApp,
        multi: true,
        deps: [AuthenticationService, Location],
    },
    provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode() && !isPlatform('capacitor'), // isDevMode() is set by `"optimization": true` in angular.json
        registrationStrategy: 'registerWhenStable:30000'
    })
],
})
    .then(() => {
        console.log('[FUA] bootstrapApplication finished');
        console.log(`[FUA] platforms [${getPlatforms().join(', ')}]`, );
    });
