import {
  SecureStoragePlugin,
  SecureStoragePluginPlugin,
} from 'capacitor-secure-storage-plugin';
import { Logger, WebStorageStateStore } from 'oidc-client-ts';

export class CapacitorStateStore extends WebStorageStateStore {
  private readonly logger = new Logger('capacitor-oidc-client-ts-state-store');

  private readonly store: SecureStoragePluginPlugin;
  private readonly prefix: string;

  constructor({
    prefix = 'capacitor-oidc-client-ts.',
  }: { prefix?: string } = {}) {
    super();
    this.store = SecureStoragePlugin;
    this.prefix = prefix;
  }

  override async set(key: string, value: string): Promise<void> {
    this.logger.create(`CapacitorStateStore.set('${key}')`);

    await this.store.set({ key: `${this.prefix}${key}`, value });
  }

  override async get(key: string): Promise<string | null> {
    this.logger.create(`CapacitorStateStore.get('${key}')`);

    try {
      return (await this.store.get({ key: `${this.prefix}${key}` })).value;
    } catch (error) {
      return null;
    }
  }

  override async remove(key: string): Promise<string | null> {
    this.logger.create(`CapacitorStateStore.remove('${key}')`);

    try {
      key = `${this.prefix}${key}`;
      const item = await this.store.get({ key });
      await this.store.remove({ key });

      return item.value;
    } catch (error) {
      return null;
    }
  }

  override async getAllKeys(): Promise<string[]> {
    this.logger.create('CapacitorStateStore.getAllKeys');

    return (await this.store.keys()).value;
  }
}
